<template>

  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <!--        <left-bar-comp>-->
    <!--            <sports-left-bar-comp></sports-left-bar-comp>-->
    <!--        </left-bar-comp>-->

    <div class="content_section">
      <div class="content">
        <sub-title>카지노&슬롯</sub-title>
        <div class="main_panel">
          <div class="casino_panel">
            <div class="casino_re_panel">
              <div class="p1">
                카지노 머니
              </div>
              <div class="p2">
                <div class="t1">
                  <button type="button" class="btn-all" @click="allMoney()">전체</button>
                  <button type="button" class="btn-reset" @click="changeCasinoMoney(0)">초기화</button>
                </div>
                <div class="t2">
                  <input type="number" v-model="casinocash" placeholder="금액">
                </div>
                <div class="t3">
                  <button class="btn-change cbg1" @click="change2casinocash()" style="height: 28px;padding: 0 16px;">
                    전환
                  </button>
                </div>
              </div>
<!--              <div class="p2"-->
<!--                   style="display: flex;flex-direction: column;font-weight: normal;font-size: 12px;color: #c9c9c9">-->
<!--                                <span style="width: 100%;height: 28px;line-height: 28px">-->
<!--                                    보유머니를 카지노 머니로 전환합니다-->
<!--                                </span>-->
<!--                <span>-->
<!--                                    (게임을 이용할시)-->
<!--                                </span>-->
<!--              </div>-->
            </div>
            <div class="casino_re_panel">
              <div class="p1">
                보유머니
              </div>
              <div class="p3">
                <button class="btn-change cbg2" @click="change2sportsmoney()">전환</button>
              </div>
<!--              <div class="p2"-->
<!--                   style="display: flex;flex-direction: column;font-weight: normal;font-size: 12px;color: #c9c9c9">-->
<!--                                <span style="width: 100%;height: 28px;line-height: 28px;">-->
<!--                                     카지노 머니를 보유머니 로 전환합니다-->
<!--                                </span>-->
<!--                <span>-->
<!--                                   (게임이 종료될시)-->
<!--                                </span>-->
<!--              </div>-->
            </div>
          </div>
        </div>


        <div class="cs_panel_button">
          <button class="btn-casino" :class="{'btn-cs-active':type==1}"
                  @click="change(1)">카지노
          </button>
          <button class="btn-slot" :class="{'btn-cs-active':type==2}"
                  @click="change(2)">슬롯
          </button>
        </div>

        <div class="casino_panel" v-if="type==1">
          <div class="casino">
            <div @click="openGame(HONOR_EVO_GAMEID,HONOR_EVO_VENDOR)">
              <img src="../../assets/images/casino/balam/evo.jpg" alt="">
            </div>
            <div @click="openGame(HONOR_DREAM_GAMEID,HONOR_DREAM_VENDOR)">
              <img src="../../assets/images/casino/balam/dream.jpg" alt="">
            </div>
            <div @click="openGame(HONOR_ASIAGAME_GAMEID,HONOR_ASIAGAME_VENDOR)">
              <img src="../../assets/images/casino/balam/ag.jpg" alt="">
            </div>
            <div @click="openGame(HONOR_PRAGMATIC_GAMEID,HONOR_PRAGMATIC_VENDOR)">
              <img src="../../assets/images/casino/balam/plag.jpg" alt="">
            </div>
            <div @click="openGame(HONOR_BOTA_GAMEID,HONOR_BOTA_VENDOR)">
              <img src="../../assets/images/casino/balam/bota.jpg" alt="">
            </div>
            <div @click="altnotuseed">
              <img src="../../assets/images/casino/balam/sexygame.jpg" alt="">
            </div>
          </div>

        </div>
        <div class="casino_panel" v-if="type == 2">
          <div class="slot-btns">
            <div>
              <img src="../../assets/images/casino/balam/sloat-evoplay.jpg"
                   @click="getGameListByCode('EVOPLAY')"
                   alt="">
            </div>

            <div>
              <img src="../../assets/images/casino/balam/sloat-plagmatic.jpg"
                   @click="getGameListByCode('PragmaticPlay')" alt="">
            </div>

            <div>
              <img src="../../assets/images/casino/balam/sloat-hanebaro.jpg"
                   @click="getGameListByCode('Habanero')"
                   alt="">
            </div>

            <div>
              <img src="../../assets/images/casino/balam/sloat-cq9.jpg" @click="getGameListByCode('CQ9')"
                   alt="">
            </div>
            <div>
              <img src="../../assets/images/casino/balam/sloat-playstar.jpg"
                   @click="getGameListByCode('PlayStar')"
                   alt="">
            </div>

            <div>
              <img src="../../assets/images/casino/balam/sloat-gameart.jpg"
                   @click="getGameListByCode('GameArt')"
                   alt="">
            </div>

            <div>
              <img src="../../assets/images/casino/balam/sloat-relax.jpg"
                   @click="getGameListByCode('Relax Gaming')"
                   alt="">
            </div>

            <div>
              <img src="../../assets/images/casino/balam/sloat-boongo.jpg"
                   @click="getGameListByCode('Booongo')"
                   alt="">
            </div>

            <div>
              <img src="../../assets/images/casino/balam/sloat-playson.jpg"
                   @click="getGameListByCode('PlaySon')"
                   alt="">
            </div>

            <div>
              <img src="../../assets/images/casino/balam/sloat-nolimit.jpg"
                   @click="getGameListByCode('Nolimit City')"
                   alt="">
            </div>

          </div>
          <div class="search">
            <input type="text" v-model="searchVal" @input="search" placeholder="슬롯 2글자 이상 검색">
          </div>
          <div class="slot_game">
            <div class="slot-item" v-for="(item,index) in slotGameList" :key="index"
                 @click="openGame(item.id,item.vendor)" v-if="item.isshow">
              <img :src="item.thumbnail" alt="">
              <span class="name">{{ item.langs['ko'] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- right 메뉴 -->
    <!--        <right-bar-comp>      -->
    <!--            <user-info-comp slot="userinfo"></user-info-comp>-->
    <!--            <right-buttons-comp slot="btns"></right-buttons-comp>       -->
    <!--            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>-->
    <!--            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>-->
    <!--        </right-bar-comp>-->

    <!--위로가기-->
    <!--        <scroll-top-comp></scroll-top-comp>-->
    <foot-comp></foot-comp>

  </div>


</template>

<script>
import RightBarComp from "../../components/RightBarComp";
import LeftBarComp from "../../components/LeftBarComp";
import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
import ScrollTopComp from "../../components/ScrollTopComp";
import SubTitle from "../../components/SubTitle";
import TopbarComp from "../../components/TopbarComp";
import FootComp from "../../components/FootComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import {
  addMemberCasinoCash,
  getAccountBalance, getCasinoMaintaining,
  getGameList, getGameUrl2, subjectMemberCasinoCash
} from "../../network/casinoHonorRequest";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import sportsConst from "../../common/sportsConst";
import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
import {postionMixin} from "../../common/mixin";
import UserInfoComp from "../../components/UserInfoComp";
import RightButtonsComp from "../../components/RightButtonsComp";

export default {
  name: "CasinoSlotGameHonor",
  mixins: [postionMixin],
  components: {
    RightButtonsComp,
    UserInfoComp,
    SportsBetCartComp,
    SportsLeftBarComp,
    RightBarBannerComp,
    FootComp, TopbarComp, SubTitle, ScrollTopComp, LeisureLeftComp, LeftBarComp, RightBarComp
  },
  data() {
    return {
      slotComp: [
        {'code': 'EVOPLAY', 'name': '에보플레이', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
        {'code': 'PragmaticPlay', 'name': '프로그매틱', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
        {'code': 'Booongo', 'name': '부운고', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
        {'code': 'Habanero', 'name': '하바네로', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
        {'code': 'CQ9', 'name': 'CQ9', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
        {'code': 'PlayStar', 'name': '플레이 스타', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
        {'code': 'GameArt', 'name': '게임아트', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
        {
          'code': 'Relax Gaming',
          'name': 'Relax Gaming',
          'img': '../../assets/images/casino/sloat-evoplay.jpg'
        },
      ],

      currentSlotCode: 'EVOPLAY',
      slotGameList: [],
      slotGameListEVO: [],
      slotGameListPLAG: [],
      slotGameListBOONGO: [],
      slotGameListHABANERO: [],
      slotGameListCQ9: [],
      slotGameListPLAYSTAR: [],
      slotGameListGAMEART: [],
      slotGameListRELAX: [],
      sportsConst,
      casinocash: 0,
      position: "Casino",
      searchVal: '',
      type: 1,
      HONOR_PROVIDER_QX: "QX",
      HONOR_PROVIDER_UX: "UX",
      HONOR_PROVIDER_CX: "CX",
      HONOR_PROVIDER_BO: "BO",

      HONOR_EVO_VENDOR: "evolution",
      HONOR_EVO_GAMEID: "evolution_baccarat_sicbo",

      HONOR_DREAM_VENDOR: "DreamGame",//CX
      HONOR_DREAM_GAMEID: "dgcasino",//CX

      HONOR_ASIAGAME_VENDOR: "Asia Gaming", //CX
      HONOR_ASIAGAME_GAMEID: "B001", //CX

      HONOR_PRAGMATIC_VENDOR: "PragmaticPlay Live",//CX
      HONOR_PRAGMATIC_GAMEID: "401",//CX

      HONOR_BOTA_VENDOR: "bota",//bo
      HONOR_BOTA_GAMEID: "bota",//bo
    }
  },
  methods: {
    change(type) {
      this.type = type
    },
    search() {
      if (this.searchVal === '') {
        this.slotGameList.forEach(g => {
          this.$set(g, 'isshow', true)
        });
      } else {

        this.slotGameList.forEach(g => {
          if (g['langs'].ko.indexOf(this.searchVal) !== -1) {
            this.$set(g, 'isshow', true)
          } else {
            this.$set(g, 'isshow', false)
          }

        });
      }

      console.log()
    },
    openGame(gameId, provider) {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getGameUrl2(gameId, provider).then(res => {
        if (res.data.success) {
          let url = res.data.data;
          window.open(url, '_blank')
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
        this.$store.commit(RECEIVE_HIDE_LOADING)
      })
    },
    getGameListByCode(vendor) {
      this.$store.commit(RECEIVE_SHOW_LOADING)

      getGameList(vendor).then(res => {
        if (res.data.success) {
          this.slotGameList = JSON.parse(res.data.data);
          this.slotGameList.forEach(g => {
            this.$set(g, 'isshow', true)
          });
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
        this.$store.commit(RECEIVE_HIDE_LOADING)
      });
    },
    change2casinocash() {
      if (this.casinocash > this.$store.state.userInfo.cash) {
        this.$swal({
          title: '보유금액 부족',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      addMemberCasinoCash(this.casinocash).then(res => {
        if (res.data.success) {
          this.$swal({
            title: '전환완료',
            type: 'success',
            showCancelButton: false,
            showConfirmButton: true
          })
          this.casinocash = 0;
          this.$store.dispatch('actionUserInfo')
          setTimeout(() => {
            this.getCasinoBalance();
          }, 1300)

        } else {
          this.$swal({
            title: 'res.data.msg',
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    change2sportsmoney() {
      this.$swal({
        title: '보유 머니 전환',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          subjectMemberCasinoCash().then((res) => {
            if (res.data.success) {
              this.$swal({
                title: '전환완료',
                type: 'success',
                showCancelButton: false,
                showConfirmButton: true
              })
              this.casinocash = 0;
              this.$store.dispatch('actionUserInfo')
              setTimeout(() => {
                this.getCasinoBalance();
              }, 1300)
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }

          })
        }
      })

    },
    getCasinoBalance() {
      getAccountBalance().then(res => {
        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
      })
    },
    allMoney() {
      this.casinocash = this.$store.state.userInfo.cash

    },
    changeCasinoMoney(cash) {
      if (cash === 0) {
        this.casinocash = 0;
      } else {
        this.casinocash += parseInt(cash);
      }
    },
    altnotuseed() {
      this.$swal({
        title: '현재 섹시게이밍 은 이용이 불가능 합니다',
        type: 'error',
        showCancelButton: false,
        showConfirmButton: true
      })
    }

  },
  created() {
    if (this.$store.state.isLogin) {
      if (this.$route.query.type) {
        this.type = this.$route.query.type
        this.change(this.type)
      }
      getCasinoMaintaining().then(res => {
        if (res.data.success) {
          if (!res.data.data) {
            this.$swal({
              title: '카지노 점검중',
              type: 'error',
              showCancelButton: false,
              showConfirmButton: true
            })
          } else {
            this.getGameListByCode(this.currentSlotCode);
            if (this.$store.state.isLogin) {
              this.getCasinoBalance()
            }
          }
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }

      })
    }
  }
}
</script>

<style scoped>
@import url("../../assets/css/base.css");
@import url("../../assets/css/common.css");
@import url("../../assets/css/style.css");
@import url("../../assets/css/media.css");
@import url("../../assets/css/sports.css");

.casino_panel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.casino_panel .casino_re_panel {
  width: 49.6%;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--noticeBg);
}

.casino_panel .casino_re_panel .p1 {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.casino_panel .casino_re_panel .p2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  padding: 5px 0;
  color: #fff9ff;
  font-size: 14px;
  font-weight: 600;
}

.casino_panel .casino_re_panel .p2 .t1 {
  text-align: center;
  width: 30%;
}

.casino_panel .t1 .btn-all {
  background: #353539;
}

.casino_panel .t1 .btn-reset {
  background: #353539;
  margin-left: 5px;
}

.casino_panel .t1 button {
  height: 28px;
  box-sizing: border-box;
  padding: 0 3px;
}

.casino_panel .casino_re_panel .p2 .t2 {
  text-align: center;
  width: 45%;
}

.t2 input {
  width: 90%;
  text-align: center;
  background-color: #ffffff;
  height: 28px;
  color: #0b1423;
}

.casino_panel .casino_re_panel .p2 .t3 {
  text-align: center;
  width: 25%;
}

.casino_panel .casino_re_panel .p2 .t3 button {
  height: 28px;
  padding: 0 3px;
  border-radius: 1px;
}

.casino_panel .casino_re_panel .p2 .btn-all {
  background: #353539;
}

.casino_panel .casino_re_panel .p2 .btn-reset {
  background: #353539;
}

.casino_panel .casino_re_panel .p3 {
  text-align: center;
}

.casino_panel .casino_re_panel .btn-change {
  height: 28px;
  padding: 0 16px;
  border-radius: 1px;
  color: #353535;
  font-weight: 600;
}

.casino_panel .casino_re_panel .cbg1 {
  background: linear-gradient(180deg, #fffd4f, #fffd70) !important;
}

.casino_panel .casino_re_panel .cbg2 {
  background: linear-gradient(180deg, #ac7eff, #c09eff) !important;
}

.casino_panel .slot-btns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.casino_panel .slot-btns div {
  width: 19.5%;
  text-align: center;
  box-sizing: border-box;
  padding: 2px;
}

.casino_panel .slot-btns img {
  cursor: pointer;
  width: 100%;
}

.casino_panel .btn-slot-comp {
  background-color: var(--btn01Bg);
  margin-bottom: 2px;
  height: 32px;
  line-height: 32px;
  border-radius: 1px;
  color: #fff9ff;
  font-weight: normal;

}


.casino_panel .search {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.casino_panel .search input {
  color: #0c0e0e;
  background-color: #cfcfd1;
  height: 32px;
  text-align: center;
}

.cs_panel_button {
  margin: 5px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.btn-casino {
  padding: 20px 0;
  width: 49.5%;
  background: linear-gradient(180deg, #212121, #3b3a3a) !important;
  color: #fff9ff;
  border-radius: 2px;
}

.btn-slot {
  border-radius: 1px;
  padding: 20px 0;
  width: 49.5%;
  background: linear-gradient(180deg, #212121, #3b3a3a) !important;
}

.btn-cs-active {
  background: linear-gradient(180deg, #d4d10d, #f6f499) !important;
  color: #353535;
  font-weight: 600;
}

@media screen and (max-width: 1024px) {
  .casino_panel .casino_re_panel {
    width: 100% !important;
    margin-top: 5px !important;
  }
}

.button_group a {
  margin-top: 3px;
}

.casino_panel .casino {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.casino_panel .casino div {
  width: 33.3%;
  box-sizing: border-box;
  padding: 10px 10px;
  text-align: center;
}

.casino_panel .casino {
  max-width: 100%;
  cursor: pointer;
}

.casino img {
  width: 100%;
  border-radius: 1px;
}

.casino_panel .slot_company {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
  margin: 10px auto;
}


.casino_panel .slot_game {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 10px;
}

.casino_panel .slot_game .slot-item {
  min-width: 150px;
  max-width: 200px;
  border-bottom: 1px solid #4a4a4a;
  cursor: pointer;
  margin: 5px 5px;
}

.casino_panel .slot_game .name {
  display: block;
  line-height: 50px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
}

.casino_panel .slot_game img {
  width: 100%;
  border: 1px solid #9a9a9a;
  border-radius: 5px;
}

@media screen and (max-width: 800px) {
  .casino div {
    width: 49.5% !important;
    box-sizing: border-box;
    padding: 10px 10px;
  }

  .casino_panel .slot_game div {
    width: 45.5%;
  }

  .casino_panel .slot_company a {
    width: 48% !important;
  }

  .casino_panel .board_box {
    width: 100% !important;
  }

  .casino_panel .slot-btns div {
    width: 45% !important;
  }

}
</style>